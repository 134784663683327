.bullets {
  position: fixed;
  bottom: 0;
  right: 100px;
  bottom: 100px;
  li {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: $bulletsOff;
    margin: 0 0 15px;
    list-style: none;
    cursor: pointer;

    &.active,
    &:hover {
      background-color: $bulletsOn;
    }

    .linkElement {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and (max-width: 959px) {
    right: 30px;
    bottom: 30px;
  }
}
