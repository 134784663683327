.homepage {
  .leftPanel {
    .content {
      position: absolute;
      top: 50%;
      left: 42%;
      transform: translate(-50%, -50%);

      h1 {
        font-weight: $latoBlack;
        font-size: 36px;
      }

      p {
        color: $mov;
        font-size: 25px;
        margin: 20px 0 0;

        span {
          color: $black;
        }
      }
    }
  }
}