.reportExcerpt {
  .rightPanel {
    h1 {
      padding: 1rem 3rem;
    }

    p {
      color: $black;
      font-size: 15px;
      padding: 1rem;
      max-width: 70%;
      margin: 0 auto;
      line-height: 1.7;
      letter-spacing: 1px;

      &:last-child {
        padding-bottom: 10rem;
      }

      &.report-notif {
        max-width: 400px;
      }
    }

    background-color: $rightPanelBg;
  }

  @media only screen and (max-width: 959px) {
    .rightPanel {
      h1 {
        padding: 3rem 3rem 0;
      }
    }
  }

  @media only screen and (min-width: 960px) {
    .rightPanel {
      p {
        font-size: 20px;
      }
    }
  }
}
