
$mov: #5c5cff !default;
$black: #000 !default;
$white: #fff !default;
$gray: #f1f1f3 !default;
$darkGray: #5c5c5e !default;
$bulletsOff: #cdcdd1 !default;
$bulletsOn: #5c5cff !default;
$rightPanelBg: #f1f1f3 !default;

$family: Poppins;
$familyLato: 'Lato', sans-serif;
$latoRegular: 400 !default;
$latoBold: 700 !default;
$latoBlack: 900 !default;