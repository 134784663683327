@media only screen and (max-width: 959px) {
  .leftPanel,
  .rightPanel {
    min-height: 50vh;
  }

  .logo,
  .logoDark {
    left: 80px !important;
  }

  .logoDark{
    max-width: 150px !important;
    width: 150px !important;
  }
}
