@media only screen and (min-width: 960px) {
  html,
  body,
  #root {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .leftPanel,
  .rightPanel {
    position: relative;
    height: 100vh;
  }

  @media only screen and (max-height: 650px) {
    .findOutMore {
      .leftPanel {
        .content {
          top: 42%;
        }
      }
    }
  }
}
