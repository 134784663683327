.findOutMore {
  .leftPanel {
    background: $gray;
  }
  .content {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;

    .contactForm {
      margin: 3rem 0;
    }
  }

  h1 {
    font-size: 30px;
    color: $mov;
    letter-spacing: 4px;

  }

  h2 {
    font-size: 36px;
    text-transform: uppercase;
    font-weight: bold;
    font-weight: $latoBlack;
    margin: 20px 0 0;
  }

  h4 {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 0 3rem;
  }

  .enabledBtn,
  .disabledBtn{
    border-radius: 20px;
    padding: 20px;
    line-height: 2;
    width: auto;
    text-align: center;
    color: $white;
    text-transform: uppercase;
    font-weight: 900;
    display: block;
    margin: 0;
    font-size: 14px;
    min-width: 250px;
    max-width: 250px;
  }

  .enabledBtn{
    background-color: $mov;
  }

  .disabledBtn{
    background-color: $darkGray;
  }

  @media only screen and (max-width: 959px) {
    .leftPanel,
    .rightPanel {
      min-height: 100vh;
    }

    .rightPanel {
      display: none;
    }
  }

  @media only screen and (max-height: 650px) {
    .leftPanel {
      height: 950px;

      .content {
        top: 50%;
      }
    }
  }
}
