@import "includes/main";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: $familyLato;
  letter-spacing: 1px;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100%;
  background-color: #f8f8f8;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

img {
  max-width: 100%;
}

h1,
p {
  //font-family: "Poppins";
  width: 100%;
  text-align: left;
}

.leftPanel,
.rightPanel {
  position: relative;
}

.logo {
  max-width: 130px;
  position: relative;
  top: 40px;
  left: 40px;
}

.logoDark {
  max-width: 130px;
  position: absolute;
  top: 40px;
  right: 40px;
}

.d-none {
  display: none;
}

.m-t-12rem {
  margin-top: 12rem !important;
}
